import React, { useState } from 'react';
import './EventList.scss';
import { IEvent } from '../interfaces/IEvent';
import { LazyImage } from './LazyImage';
import DateHelper from '../helpers/DateHelper';
import CacheHelper from '../helpers/CacheHelper';
import { useRecoilState } from 'recoil';
import loaderState from '../atoms/loaderState';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { DatesModal } from '../modals/Dates/DatesModal';
import Loader from './Loader';

interface IProps {
  events: IEvent[];
  hideOrganisation?: boolean;
  hideVenue?: boolean;
}

const EventList: React.FC<IProps> = (props: IProps) => {
  const { events } = props;
  const [selectedEvent, setSelectedEvent] = useState<IEvent>(null);
  const navigate = useNavigate();
  const [loadingEventTag, setLoadingEventTag] = useState(null);

  const onEventClick = async (_event) => {
    if (loadingEventTag) return;
    setLoadingEventTag(_event.EventTag);

    const e = await CacheHelper.eventByTag(_event.EventTag);

    if (!e.TourId && e.Dates.length === 1) {
      if (e.Dates[0].External && e.Dates[0].ExternalUrl && e.Dates[0].ExternalUrl.length > 0) {
        var r = window.confirm(
          'You are about to leave Seaty.co.uk, we take no responsibility for ticket sales that occur outside of our domain. Click continue to go to the external ticket sales provider.',
        );
        if (r == true) {
          window.location.href = e.Dates[0].ExternalUrl;
          return;
        } else {
          return;
        }
      }

      navigate(`/${e.EventTag}/tickets/${moment(e.Dates[0].DateAsString).format('DDMMMYYYY/HHmm')}`);
    } else {
      setSelectedEvent(e);
    }
    setLoadingEventTag(null);
  };

  return (
    <div className="event-list">
      {events.map((event) => {
        const venueName = event.Venue ? event.Venue.Name : event.VenueName;
        const organisationNameMatchesVenueName = event.Organisation && venueName.trim().toLowerCase() == event.Organisation.Name.trim().toLowerCase();

        return (
          <button key={`EventList_${event.Id}`} disabled={event.EventTag == loadingEventTag} className={`event-list-item`} onClick={() => onEventClick(event)}>
            <LazyImage src={event.ImageUrl} alt={`${event.Name} Event`} />
            <div className="event-info">
              <div className="event-name">{event.Name}</div>
              {!props.hideVenue && !event.TourId && (props.hideOrganisation || !organisationNameMatchesVenueName) && <div className="event-venue">{venueName}</div>}
              <div className="event-dates">{DateHelper.fromTo(event.MinDate, event.MaxDate)}</div>
              {!props.hideOrganisation && event.Organisation && <div className="event-organisation">{event.Organisation.Name}</div>}
              <div className="ticket-rip bottom" />
            </div>
            {event.EventTag == loadingEventTag && <Loader inline />}
          </button>
        );
      })}
      {selectedEvent && <DatesModal onClose={() => setSelectedEvent(null)} event={selectedEvent} />}
    </div>
  );
};

export default EventList;
