import { FunctionComponent, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import AdminApi from '../../../../api/AdminApi';
import Loader from '../../../../components/Loader';
import { IEvent } from '../../../../interfaces/IEvent';
import { IOrganisation } from '../../../../interfaces/IOrganisation';
import { OrderDashboardModal } from '../../../../modals/AdminOrderModal';
import SVGMultiply from '../../../../svg/SVGMultiply';
import AdminFeedRow from './AdminFeedRow';
import { FeedType, IFeedRow } from './IFeedRow';

export interface IProps {
  organisation: IOrganisation;
  event?: IEvent;
  superAdmin?: boolean;
}

const AdminFeed: FunctionComponent<IProps> = (props) => {
  const { event, organisation, superAdmin } = props;
  const organisationTag = organisation.OrganisationTag;
  const [category, setCategory] = useState<FeedType>(FeedType.All);

  const [filter, setFilter] = useState('');

  const [purchases, setPurchases] = useState<IFeedRow[]>([]);
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState('');
  const [selectedRow, setSelectedRow] = useState<IFeedRow>(null);

  const isSeatyDashboard = superAdmin && organisationTag.toLowerCase() == 'seaty';

  const refresh = (silent?: boolean) => {
    if (isSeatyDashboard && (!filter || filter.length == 0)) {
      setLoading(false);
      return;
    }

    setLoading(!silent);

    const query = isSeatyDashboard
      ? `/api/Feed?filter=${filter}`
      : `/api/Feed?organisationTag=${organisationTag}${event ? `&eventTag=${event.EventTag}` : ''}${filter && filter.length > 0 ? `&filter=${filter}` : ''}`;

    AdminApi.request('GET', query)
      .then((response) => {
        setLoading(false);
        setPurchases(response);
      })
      .catch((error) => alert('Error: ' + error));
  };

  useEffect(() => {
    refresh();
  }, [filter]);

  const onFilterKeyPress = (e: any) => {
    if (e.key == 'Enter') {
      setFilter(query);
    }
  };

  const clearFilterClicked = () => {
    setFilter('');
    setQuery('');
  };

  const handleSearchChanged = (e) => {
    setQuery(e.target.value);
  };

  if (!organisation) return null;
  if (loading) return <Loader inline>Loading feed...</Loader>;

  return (
    <>
      <Helmet>
        <title>#{organisationTag} Feed</title>
        <meta name="description" content={`Manage purchases for your organisation.`} />
      </Helmet>

      <div className="toolbar">
        <div className="title">{event ? 'Event' : 'Organisation'} Feed</div>
        <div className="buttons"></div>
      </div>

      <div className="row">
        <div className="col-sm-8">
          {purchases?.length > 0 && (
            <div className="admin-button-menu inline">
              <button className={category == FeedType.All ? 'selected' : ''} onClick={() => setCategory(FeedType.All)}>
                All
              </button>
              <button className={category == FeedType.OnlinePurchase ? 'selected' : ''} onClick={() => setCategory(FeedType.OnlinePurchase)}>
                Online
              </button>
              <button className={category == FeedType.AdminOrder ? 'selected' : ''} onClick={() => setCategory(FeedType.AdminOrder)}>
                Admin
              </button>
              <button className={category == FeedType.RequestOrder ? 'selected' : ''} onClick={() => setCategory(FeedType.RequestOrder)}>
                Request
              </button>
              <button className={category == FeedType.RefundedPurchase ? 'selected' : ''} onClick={() => setCategory(FeedType.RefundedPurchase)}>
                Refunded
              </button>
              <button className={category == FeedType.FailedOnlinePurchase ? 'selected' : ''} onClick={() => setCategory(FeedType.FailedOnlinePurchase)}>
                Failed
              </button>
            </div>
          )}
        </div>

        <div className="col-sm-4">
          <div className="donotprint" style={{ margin: '0' }}>
            <div className="input">
              <input id="searchinput" onChange={handleSearchChanged} type="text" placeholder="Search..." value={query} onKeyPress={onFilterKeyPress.bind(this)} style={{ margin: '0' }} />

              <button onClick={clearFilterClicked}>
                <SVGMultiply />
              </button>
            </div>
          </div>
        </div>
      </div>

      {purchases?.length > 0 ? (
        <div className="orderoverview" style={{ paddingBottom: '100px' }}>
          <div className="container-outer" style={{ minHeight: '300px' }}>
            <div className="container-inner" style={{ backgroundColor: 'white' }}>
              <table className="table grid-table">
                <thead>
                  <tr>
                    <th className="grid-header" style={{ width: '100px' }}>
                      Amount
                    </th>
                    <th className="grid-header" style={{ width: '200px' }}>
                      Type
                    </th>
                    <th className="grid-header" style={{ width: '100px' }}>
                      #
                    </th>
                    {!event && <th className="grid-header">Event</th>}
                    <th className="grid-header">Email</th>
                    <th className="grid-header" style={{ width: '155px' }}>
                      Date
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {purchases
                    .filter((p) => category == FeedType.All || (category == FeedType.OnlinePurchase && p.Type == FeedType.OnlineBalancePayment) || p.Type == category)
                    .map((p) => (
                      <AdminFeedRow
                        timeZoneIana={event && event.TimeZoneIana}
                        showEventTag={!event}
                        currency={organisation.Currency.Symbol}
                        organisationTag={organisationTag}
                        handleRowClick={() => setSelectedRow(p)}
                        key={p.Type + '_' + p.OrderId + '_' + p.PurchaseId + '_' + p.RefundId}
                        organisationId={organisation.Id}
                        data={p}
                      />
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : (
        <div className="info">There are no results available.</div>
      )}

      {selectedRow && selectedRow.OrderId && (
        <>
          <OrderDashboardModal
            eventTag={selectedRow.EventTag}
            onClose={() => {
              setSelectedRow(null);
              refresh(true);
            }}
            orderId={selectedRow.OrderId}
          />
        </>
      )}
    </>
  );
};

export default AdminFeed;
