import linq from 'linq';
import moment from 'moment';
import 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Loader from '../../components/Loader';
import EnvironmentVariables from '../../EnvironmentVariables';
import CacheHelper from '../../helpers/CacheHelper';
import JsonFileHelper from '../../helpers/JsonFileHelper';
import { IEvent } from '../../interfaces/IEvent';
import { DatesModal } from '../../modals/Dates/DatesModal';
import EventList from '../../components/EventList';

const HomePage: React.FC = () => {
  const [loading, setLoading] = useState('Loading featured events...');
  const [featuredEvents, setFeaturedEvents] = useState<Array<IEvent>>(CacheHelper.featuredEvents);
  const [selectedEvent, setSelectedEvent] = useState<IEvent>(null);

  useEffect(() => {
    if (CacheHelper.featuredEvents.length === 0) {
      try {
        setLoading('Loading featured events...');
        JsonFileHelper.download(`https://seatylive.blob.core.windows.net/featured-cache${EnvironmentVariables.isDev() ? '-test' : ''}/featuredEvents.json?_=${new Date().getTime()}`)
          .then((result) => {
            setFeaturedEvents(result.Events);
            CacheHelper.featuredEvents = result.Events;
            setLoading(null);
          })
          .catch((e) => {
            alert(JSON.stringify(e));
          });
      } catch (ex) {
        alert(JSON.stringify(ex));
      }
    } else {
      setLoading(null);
    }
  }, []);

  let events = linq
    .from(featuredEvents)
    .where((event) => {
      const now = event && moment().tz(event.TimeZoneIana);
      const onlineFrom = event && moment(event.OnlineFromDate).tz(event.TimeZoneIana);
      const salesStarted = event && onlineFrom <= now;

      const maxDate = event && moment(event.MaxDate).add(12, 'hours').tz(event.TimeZoneIana);
      const maxDateInReasonableRange = event && maxDate >= now;

      return salesStarted && maxDateInReasonableRange && (event.TourId == null || event.TourId != 13);
    })
    .orderBy((e) => moment.utc(e.MinDate).unix())
    .groupBy((e) => e.TourId)
    .selectMany((e) => {
      const event = e.first();
      if (event.TourId) {
        return [{ ...e.first(), MinDate: event.MinDate, MaxDate: e.last().MaxDate }];
      } else {
        return e.toArray();
      }
    })
    .orderBy((e) => moment.utc(e.MinDate).unix())
    .toArray();

  const featuredOrganisationsWithMoreThanOneEvent = linq
    .from(events)
    .groupBy((e) => e.Organisation.Id)
    .where((o) => o.count() > 0);

  const maxEvents = 2;

  return (
    <>
      <Helmet>
        <title>Seaty - A simple box office platform</title>
        <meta name="description" content={`The simple, free online box office. Sign up and let us help you sell your tickets!`} />
      </Helmet>
      <Header alwaysShowSearch={true} />
      <div className="events-list-strip-block-container">
        <div className="events-list-strip-block-rows">
          {featuredOrganisationsWithMoreThanOneEvent
            .select((o) => {
              var organisation = o.first().Organisation;

              return (
                <div key={organisation.Id} className={`strip-block events-list-strip-block ${o.count() > 1 ? 'events-list-more-than-one-event' : ''}`}>
                  <div className="events-list-title">
                    <Link className="events-list-see-more" to={EnvironmentVariables.HOME_URL + '/Organisation/' + organisation.OrganisationTag}>
                      {organisation.Name}
                    </Link>
                  </div>

                  <EventList hideOrganisation={organisation.Featured} events={o.take(maxEvents).toArray()} />
                </div>
              );
            })
            .toArray()}
        </div>
      </div>

      <Footer />
      {selectedEvent && <DatesModal onClose={() => setSelectedEvent(null)} isAdmin={false} eventTag={selectedEvent.EventTag} />}
      {loading && <Loader>{loading}</Loader>}
    </>
  );
};

export default HomePage;
