import 'moment-timezone';
import { FunctionComponent, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useRecoilState } from 'recoil';
import tourMenuState from '../../atoms/tourMenuState';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Loader from '../../components/Loader';
import Theme from '../../components/Theme';
import EnvironmentVariables from '../../EnvironmentVariables';
import CacheHelper from '../../helpers/CacheHelper';
import LocationHelper from '../../helpers/LocationHelper';
import ThemeHelper from '../../helpers/ThemeHelper';
import UserHelper from '../../helpers/UserHelper';
import { IOrganisation } from '../../interfaces/IOrganisation';
import { ITour } from '../../interfaces/ITour';
import AdminPage from '../Admin/AdminPage';
import OrganisationDetails from '../OrganisationPage/OrganisationDetails';
import TourDetails from './TourDetails';
import './TourPage.scss';
import PixelHelper from '../../helpers/PixelHelper';
import NeedHelp from '../../components/NeedHelp';

export interface IProps {}

const TourLandingPage: FunctionComponent<IProps> = (props) => {
  const [tour, setTour] = useState<ITour>();
  const [organisation, setOrganisation] = useState<IOrganisation>();
  const eventUrlDetails = LocationHelper.getLocation().pathname;
  const tourTag = eventUrlDetails.split('/')[2];
  const [loading, setLoading] = useState('');
  const [, setTourMenu] = useRecoilState(tourMenuState);

  useEffect(() => {
    const fetchData = async () => {
      setLoading('Loading tour details...');
      const tourCache: ITour = await CacheHelper.tourByTag(tourTag);

      setLoading('Loading organisation details...');
      const organisationCache = await CacheHelper.organisationById(tourCache.OrganisationId);
      setOrganisation(organisationCache);
      setTour(tourCache);

      setLoading(null);

      let pixel = false;
      if (tourCache.FacebookPixelId) {
        pixel = true;
        PixelHelper.init(tourCache.FacebookPixelId, null, {
          autoConfig: true,
          debug: EnvironmentVariables.isDev(),
        });
      }
      if (organisationCache.OrganisationTag) {
        pixel = true;
        PixelHelper.init(organisationCache.FacebookPixelId, null, {
          autoConfig: true,
          debug: EnvironmentVariables.isDev(),
        });
      }
      if (pixel) PixelHelper.pageView();
    };

    fetchData();
  }, []);

  if (loading) return <Loader>{loading}</Loader>;

  const currentUser = UserHelper.currentUser;
  const isAdmin = tour && currentUser && (currentUser.Id === 1 || (currentUser.Tours && currentUser.Tours.includes(tour.Id)));

  const tourPage = tour && (
    <div className="tour-page">
      <TourDetails isAdmin={isAdmin} organisation={organisation} noBorder={true} tour={tour} />

      {organisation && <OrganisationDetails organisation={organisation} />}

      <NeedHelp organisation={organisation} />
    </div>
  );

  const theme = tour && tour.Theme;
  const useTheme = theme && theme.BackgroundImageUrl !== ThemeHelper.getDefaultTheme().BackgroundImageUrl;

  return (
    <>
      <Helmet>
        <meta property="og:url" content={`https://Seaty.co.uk/Tour/${tourTag}`} />
        <meta property="fb:app_id" content="747095462055934" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`#${tourTag}`} />
        <meta property="og:description" content={`#${tourTag}.`} />
        <meta property="og:image" content={tour ? tour.LogoUrl : ''} />
        <title>#{tourTag}</title>
        <meta name="description" content={`#${tourTag}.`} />
      </Helmet>

      {isAdmin ? (
        <AdminPage>
          <div className="organisation-page">
            {useTheme ? (
              <Theme hideFooter backgroundClassName={`seaty-modal-background`} parentClassName="tour-theme" className="" theme={theme}>
                <div className={`ticket-rip`} />
                {tourPage}
                <div className="ticket-rip page bottom" />
              </Theme>
            ) : (
              <>{tourPage}</>
            )}
          </div>
        </AdminPage>
      ) : (
        <>
          <Header transparent={useTheme} />

          {useTheme ? (
            <Theme hideFooter backgroundClassName={`seaty-modal-background`} parentClassName="tour-theme" className="" theme={theme}>
              <div className={`ticket-rip${isAdmin ? ' dark' : ''}`} />
              {tourPage} <div className="ticket-rip bottom" />
            </Theme>
          ) : (
            tourPage
          )}

          <Footer transparent={useTheme} />
        </>
      )}
    </>
  );
};

export default TourLandingPage;
