import React from 'react';
import InfoInput from '../../../components/InfoInput';
import InfoLabel from '../../../components/InfoLabel';
import { IOrganisation } from '../../../interfaces/IOrganisation';
import ImageUploader, { IImageUploadRequest, ImageRequestGroup } from '../ImageUploader';
import Toolbar from '../toolbar/Toolbar';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import EventHelper from '../../../helpers/EventHelper';
import Constants from '../../../helpers/Constants';

export interface IProps {
  organisation: IOrganisation;
  onOrganisationUpdated: (e: IOrganisation) => void;
  onImageRequested: (request: IImageUploadRequest) => void;
  imageRequests: { [key: string]: IImageUploadRequest };
  globalOptions: JSX.Element;
  setBusyMessage: (value: string) => void;
}

const OrganisationSection: React.FC<IProps> = (props) => {
  const { organisation, onOrganisationUpdated, imageRequests, onImageRequested } = props;
  const description = organisation.Description ? organisation.Description : '';

  const charsUsed = EventHelper.getRichTextCharLength(description);
  const charsRemaining = Constants.MaxEventDescriptionLength - charsUsed;

  if (organisation.OrganisationTag == null) {
    organisation.OrganisationTag = '';
  }

  return (
    <>
      <Toolbar>
        <div className="options">{props.globalOptions}</div>
      </Toolbar>
      <div className="row">
        <div className="col-sm-8">
          <div className="fields">
            <h2>Details</h2>
            <div style={{ marginTop: '0' }}>
              <div className="section">
                <InfoLabel required text="What is your organisations name?" tooltip="An organisation name is required to define your organisation. "></InfoLabel>
                <InfoInput labelText="Name" maxLength={100} onChange={(value) => onOrganisationUpdated({ ...organisation, Name: value })} value={organisation.Name} />

                <InfoLabel
                  required
                  text="What organisation tag would you like?"
                  tooltip="An organisation tag is a short-cut link to your organisation. Anyone can jump to your organisation using Seaty.co.uk/Organisation/YourTag. "
                ></InfoLabel>
                <InfoInput
                  maxLength={22}
                  labelText="Seaty.co.uk/Organisation/"
                  onChange={(value) => onOrganisationUpdated({ ...organisation, OrganisationTag: value })}
                  value={organisation.OrganisationTag}
                />

                <InfoLabel text="Do you have a website?" tooltip="We will advertise your website on all your order emails and your event pages. "></InfoLabel>
                <InfoInput labelText="Website address" maxLength={500} onChange={(value) => onOrganisationUpdated({ ...organisation, WebsiteUrl: value })} value={organisation.WebsiteUrl} />
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-4">
          <div className="info">
            Update the core information for your organisation and press <strong>save</strong> to commit any changes.
          </div>
          {organisation.CustomFee == null ? null : (
            <div className="info">
              <strong>Information about your fees</strong>
              <br />
              This organisation has been granted a special reduced card processing fee of {organisation.CustomFee}%
            </div>
          )}
        </div>

        <div className="col-sm-8">
          <div className="fields">
            <h2>Address</h2>
            <div
              className="section"
              style={{
                marginTop: '0',
              }}
            >
              <InfoLabel required text="What is your organisations address?" tooltip="Please let us know where your organisation is based. "></InfoLabel>

              <div className="row smallmargins">
                <div className="col-sm-12">
                  <InfoInput labelText="Street address" maxLength={100} onChange={(value) => onOrganisationUpdated({ ...organisation, StreetAddress: value })} value={organisation.StreetAddress} />
                  <InfoInput labelText="City" maxLength={100} onChange={(value) => onOrganisationUpdated({ ...organisation, City: value })} value={organisation.City} />
                </div>

                <div className="col-sm-6 col-xs-8">
                  <InfoInput labelText="Postcode" maxLength={100} onChange={(value) => onOrganisationUpdated({ ...organisation, Postcode: value })} value={organisation.Postcode} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-4">
          <div className="info">Please give us your official business address details. These will not be advertised to the public.</div>
        </div>

        <div className="col-sm-8">
          <div className="fields">
            <h2>Contact</h2>
            <div style={{ marginTop: '0' }}>
              <div className="section">
                <InfoLabel
                  required
                  text="What's your box office email?"
                  tooltip="You should provide an email address your attendees can use to ask you questions about your event and get support when needed. This email address will be available from your event page. "
                ></InfoLabel>
                <InfoInput labelText="Box office email" maxLength={200} onChange={(value) => onOrganisationUpdated({ ...organisation, BoxOfficeEmail: value })} value={organisation.BoxOfficeEmail} />

                <InfoLabel
                  text="What's your box office phone number?"
                  tooltip="You should provide a box office contact number attendees can call to buy tickets from you or ask questions. Remember you can use Seaty to process tickets you sell over the phone and take card payments. "
                ></InfoLabel>
                <InfoInput labelText="Box office number" maxLength={15} onChange={(value) => onOrganisationUpdated({ ...organisation, BoxOfficeTel: value })} value={organisation.BoxOfficeTel} />
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-4">
          <div className="info">
            You must provide a means for customers to contact you about your event. We will take every step we can to help your customers but they should have access to contact you with questions
            about your event or for support when required.
          </div>
        </div>

        <div className="col-sm-8">
          <div className="fields">
            <h2>About your organisation</h2>
            <div className="section" style={{ marginBottom: '0' }}>
              <div className="section">
                <label className="label">
                  How would you describe your organisation? ({charsUsed}/{Constants.MaxEventDescriptionLength})
                </label>
                <ReactQuill
                  theme="snow"
                  value={organisation.Description}
                  onChange={(v, a, b) => {
                    if (b != 'user') return;
                    onOrganisationUpdated({ ...organisation, Description: v });
                  }}
                  readOnly={charsUsed == charsRemaining}
                  modules={{
                    toolbar: [
                      [{ header: '1' }, { header: '2' }],
                      ['bold', 'italic', 'underline', 'strike'],
                      [{ list: 'ordered' }, { list: 'bullet' }],
                    ],
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-4">
          {charsUsed > Constants.MaxEventDescriptionLength && (
            <div className="info bad">Oops! Your organisation description is too long. Try and keep the character count to less than {Constants.MaxEventDescriptionLength}.</div>
          )}

          <div className="info">
            An organisation <strong>description</strong> 📝 is really important! Make sure to include all necessary info to accurately describe your organisation for your customers. 🎯
          </div>
        </div>

        <div className="col-sm-8">
          <div className="fields">
            <h2>Marketing</h2>
            <div className="section event-information">
              <div className="section">
                <InfoLabel
                  text="Do you have a Facebook Ads Pixel ID?"
                  tooltip="Visit Facebooks Business documentation on creating your own event pixel. This can then be used to track throuoghput from your Facebook Ads to Seaty. We will tell Facebook whenever somebody buys tickets for your events to help you better target your audience."
                />
                <InfoInput labelText="Pixel ID" onChange={(value) => onOrganisationUpdated({ ...organisation, FacebookPixelId: value })} value={organisation.FacebookPixelId} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrganisationSection;
