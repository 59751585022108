import React from 'react';
import InfoInput from '../../../components/InfoInput';
import InfoLabel from '../../../components/InfoLabel';
import { ITour } from '../../../interfaces/ITour';
import ImageUploader, { IImageUploadRequest, ImageRequestGroup } from '../ImageUploader';
import Toolbar from '../toolbar/Toolbar';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import EventHelper from '../../../helpers/EventHelper';
import Constants from '../../../helpers/Constants';

export interface IProps {
  tour: ITour;
  onTourUpdated: (e: ITour) => void;
  onImageRequested: (request: IImageUploadRequest) => void;
  imageRequests: { [key: string]: IImageUploadRequest };
  globalOptions: JSX.Element;
  setBusyMessage: (value: string) => void;
}

const TourSection: React.FC<IProps> = (props) => {
  const { tour, onTourUpdated, imageRequests, onImageRequested } = props;
  const description = tour.Description ? tour.Description : '';

  const charsUsed = EventHelper.getRichTextCharLength(description);
  const charsRemaining = Constants.MaxEventDescriptionLength - charsUsed;

  if (tour.TourTag == null) {
    tour.TourTag = '';
  }

  return (
    <>
      <Toolbar>
        <div className="options">{props.globalOptions}</div>
      </Toolbar>
      <div className="row">
        <div className="col-sm-8">
          <div className="fields">
            <h2>Details</h2>
            <div style={{ marginTop: '0' }}>
              <div className="section">
                <label className="label">What is your tours name?</label>
                <InfoInput labelText="Name" maxLength={100} onChange={(value) => onTourUpdated({ ...tour, Name: value })} value={tour.Name} />

                <InfoLabel text="What tour tag would you like? " tooltip="A tour tag is a short-cut link to your tour. Anyone can jump to your tour using Seaty.co.uk/Tour/TourTag. "></InfoLabel>
                <InfoInput maxLength={22} labelText="Seaty.co.uk/Tour/" required onChange={(value) => onTourUpdated({ ...tour, TourTag: value })} value={tour.TourTag} />
              </div>
            </div>
          </div>

          <div className="fields">
            <h2>About your tour</h2>
            <div className="section" style={{ marginBottom: '0' }}>
              <div className="section">
                <label className="label">
                  How would you describe your event? ({charsUsed}/{Constants.MaxEventDescriptionLength})
                </label>
                <ReactQuill
                  theme="snow"
                  value={tour.Description}
                  onChange={(v, a, b) => {
                    if (b != 'user') return;
                    onTourUpdated({ ...tour, Description: v });
                  }}
                  readOnly={charsUsed == charsRemaining}
                  modules={{
                    toolbar: [
                      [{ header: '1' }, { header: '2' }],
                      ['bold', 'italic', 'underline', 'strike'],
                      [{ list: 'ordered' }, { list: 'bullet' }],
                    ],
                  }}
                />{' '}
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          {charsUsed > Constants.MaxEventDescriptionLength && (
            <div className="info bad">Oops! Your tour description is too long. Try and keep the character count to less than {Constants.MaxEventDescriptionLength}.</div>
          )}

          <div className="info">
            A tour <strong>description</strong> 📝 is really important! Make sure to include all necessary info to accurately describe your tour for your customers. 🎯
          </div>
        </div>
        <div className="col-sm-4">
          <div className="info">
            Your tour image will show on your tour information page and be the main image used to promote your tour on Seaty.
            <br /> <br />
            <strong>Make sure it's a good one!</strong>
          </div>
        </div>
      </div>
    </>
  );
};

export default TourSection;
