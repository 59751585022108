import moment from 'moment';
import 'moment-timezone';
import { FunctionComponent, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useRecoilState } from 'recoil';
import AdminApi from '../../api/AdminApi';
import loaderState from '../../atoms/loaderState';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Loader from '../../components/Loader';
import Theme from '../../components/Theme';
import EnvironmentVariables from '../../EnvironmentVariables';
import CacheHelper from '../../helpers/CacheHelper';
import LocationHelper from '../../helpers/LocationHelper';
import StripeHelper from '../../helpers/StripeHelper';
import ThemeHelper from '../../helpers/ThemeHelper';
import UserHelper from '../../helpers/UserHelper';
import { IEvent } from '../../interfaces/IEvent';
import { DatesModal } from '../../modals/Dates/DatesModal';
import SVGFeature from '../../svg/SVGFeature';
import SVGTrash from '../../svg/SVGTrash';
import AdminPage from '../Admin/AdminPage';
import { EventDetails } from './EventDetails';
import './EventPage.scss';
import EventWarnings from './EventWarnings';
import PrivateEvent from './PrivateEvent';
import EventHelper from '../../helpers/EventHelper';
import PixelHelper from '../../helpers/PixelHelper';
import OrganisationDetails from '../OrganisationPage/OrganisationDetails';
import NeedHelp from '../../components/NeedHelp';

const EventLandingPage: FunctionComponent = () => {
  const [event, setEvent] = useState<IEvent>();
  const [error, setError] = useState<string>(null);
  const [loadingOverlay, setLoadingOverlay] = useState<string>(null);
  const [datePickerEvent, setDatePickerEvent] = useState<IEvent>(null);
  const [privatePasswordConfirmed, setPrivatePasswordConfirmed] = useState(false);
  const [loaders, setLoaders] = useRecoilState(loaderState);

  const eventUrlDetails = LocationHelper.getLocation().pathname;
  const eventTag = eventUrlDetails.split('/')[1];
  const organisation = event && event.Organisation;
  const isAdmin = event && UserHelper.isCurrentUserAdmin(organisation);

  const theme = ThemeHelper.getEventTheme(event);
  const useTheme = theme && theme.BackgroundImageUrl !== ThemeHelper.getDefaultTheme().BackgroundImageUrl;

  const futureDates = event && EventHelper.getFutureDates(event, isAdmin);
  const pastDates = event && EventHelper.getPastDates(event, isAdmin);

  useEffect(() => {
    const fetchEventData = async () => {
      const eventData: IEvent = await CacheHelper.eventWithMessage(setLoaders, loaders, eventTag).catch(() => {
        return new Promise((resolve) =>
          setTimeout(async () => {
            await CacheHelper.eventWithMessage(setLoaders, loaders, eventTag)
              .then((e) => resolve(e))
              .catch((err) => setError('There was an error when downloading event data, please refresh the page...'));
          }, 800),
        );
      });

      setEvent(eventData);
      StripeHelper.get(eventData.Organisation.StripeAccountId);

      let pixel = false;
      if (eventData.FacebookPixelId) {
        pixel = true;
        PixelHelper.init(eventData.FacebookPixelId, null, {
          autoConfig: true,
          debug: EnvironmentVariables.isDev(),
        });
      }

      if (eventData.Organisation && eventData.Organisation.FacebookPixelId) {
        pixel = true;
        PixelHelper.init(eventData.Organisation.FacebookPixelId, null, {
          autoConfig: true,
          debug: EnvironmentVariables.isDev(),
        });
      }

      if (eventData.Tour && eventData.Tour.FacebookPixelId) {
        pixel = true;
        PixelHelper.init(eventData.Tour.FacebookPixelId, null, {
          autoConfig: true,
          debug: EnvironmentVariables.isDev(),
        });
      }

      const _isAdmin = UserHelper.isCurrentUserAdmin(eventData.Organisation);

      if (pixel) {
        if (eventData.FacebookPixelId) PixelHelper.trackSingle(eventData.FacebookPixelId, 'PageView', { isAdmin: _isAdmin });
        if (eventData.Organisation && eventData.Organisation.FacebookPixelId) PixelHelper.trackSingle(eventData.Organisation.FacebookPixelId, 'PageView', { isAdmin: _isAdmin });
        if (eventData.Tour && eventData.Tour.FacebookPixelId) PixelHelper.trackSingle(eventData.Tour.FacebookPixelId, 'PageView', { isAdmin: _isAdmin });
      }
    };

    fetchEventData();
  }, [eventTag]);

  const now = event && moment().tz(event.TimeZoneIana);
  const onlineFrom = event && moment(event.OnlineFromDate).tz(event.TimeZoneIana);
  const salesStarted = event && onlineFrom <= now;

  if (event && event.Private && !isAdmin && !privatePasswordConfirmed) {
    return (
      <>
        <Header />
        <PrivateEvent onPasswordConfirmed={() => setPrivatePasswordConfirmed(true)} event={event} />
        <Footer />
      </>
    );
  }

  const bookTickets = async (e) => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

    if (!e.TourId && e.Dates.length === 1) {
      if (e.Dates[0].External && e.Dates[0].ExternalUrl && e.Dates[0].ExternalUrl.length > 0) {
        var r = window.confirm(
          'You are about to leave Seaty.co.uk, we take no responsibility for ticket sales that occur outside of our domain. Click continue to go to the external ticket sales provider.',
        );
        if (r == true) {
          window.location.href = e.Dates[0].ExternalUrl;
          return;
        } else {
          return;
        }
      }

      window.location.href = '/' + e.EventTag + '/tickets/' + moment(e.Dates[0].DateAsString).format('DDMMMYYYY/HHmm');
    } else {
      setDatePickerEvent(e);
    }
  };

  const eventPage = event && (
    <div className="event-page">
      {futureDates && futureDates.length == 0 && (
        <div className="info-message">📅 There are currently no event dates in the future available for this event. Please try another event or come back at a different time! </div>
      )}
      <EventWarnings event={event} eventDate={null} isAdmin={isAdmin} hasEnded={false} salesStarted={salesStarted} onlineFrom={onlineFrom} />
      <EventDetails futureDates={futureDates} pastDates={pastDates} setDatePickerEvent={(e) => bookTickets(e)} event={event} isAdmin={isAdmin} />

      {organisation && <OrganisationDetails organisation={organisation} />}

      <NeedHelp organisation={organisation} />

      {loadingOverlay && <Loader>{loadingOverlay}</Loader>}

      {datePickerEvent && <DatesModal showEventOnly={true} currentEventDate={null} onClose={() => setDatePickerEvent(null)} isAdmin={isAdmin} eventTag={datePickerEvent.EventTag} />}
    </div>
  );

  return (
    <>
      <Helmet>
        {futureDates && futureDates.length == 0 ? <meta name="robots" content="noindex" /> : null}
        <meta property="og:url" content={`https://Seaty.co.uk/${eventTag}`} />
        <meta property="fb:app_id" content="747095462055934" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`#${eventTag} - Tickets`} />
        {event && <meta property="og:image" content={event.ImageUrl} />}
        <title>#{eventTag}</title>
        <meta property="og:description" content={`Buy tickets for #${eventTag}.`} />
        <meta name="description" content={`Buy tickets for #${eventTag}.`} />
        {useTheme && theme && theme.MainColour && <meta name="theme-color" content={theme.MainColour.indexOf('#') > -1 ? theme.MainColour : '#' + theme.MainColour} />}
      </Helmet>

      {isAdmin ? (
        <AdminPage>
          {useTheme ? (
            <>
              <Theme hideFooter backgroundClassName={`seaty-modal-background`} parentClassName="event-theme" className="" theme={theme}>
                <div className={`ticket-rip`} />
                {eventPage} <div className={`ticket-rip bottom`} />
              </Theme>
            </>
          ) : (
            eventPage
          )}

          {UserHelper.currentUser && UserHelper.currentUser.Id === 1 && (
            <>
              <div className="toolbar" style={{ padding: '15px', background: 'transparent' }}>
                <div className="buttons">
                  <button
                    onClick={() => {
                      setLoadingOverlay('Configuring event...');
                      AdminApi.request('GET', `/api/FeatureEvent?id=${event.Id}`)
                        .then((result) => {
                          return CacheHelper.eventWithMessage(setLoaders, loaders, eventTag);
                        })
                        .then((e) => {
                          setEvent(e);
                          setLoadingOverlay(null);
                        });
                    }}
                  >
                    <SVGFeature />
                    {event.Featured ? 'Un-' : ''}Feature #{event.EventTag}
                  </button>

                  <button
                    onClick={() => {
                      setLoadingOverlay('Configuring organisation...');
                      AdminApi.request('GET', `/api/FeatureOrganisation?id=${organisation.Id}`)
                        .then((result) => {
                          return CacheHelper.organisationByTagWithMessage(setLoaders, loaders, organisation.OrganisationTag);
                        })
                        .then((o) => {
                          setEvent({ ...event, Organisation: o });
                          setLoadingOverlay(null);
                        });
                    }}
                  >
                    <SVGFeature />
                    {organisation.Featured ? 'Un-' : ''}Feature #{organisation.OrganisationTag}
                  </button>

                  <button
                    onClick={() => {
                      AdminApi.request('DELETE', `/api/Event`, event)
                        .then(() => {
                          alert('Archived event.');
                        })
                        .catch((message) => {
                          alert(message);
                        });
                    }}
                    className="scans"
                  >
                    <SVGTrash />
                    Archive Event
                  </button>
                </div>
              </div>
            </>
          )}
        </AdminPage>
      ) : (
        <>
          <Header hideLinks={true} transparent={useTheme} />
          {error && (
            <>
              <div className="strip-block" style={{ paddingTop: '100px', paddingBottom: '300px' }}>
                <div className="row content">
                  <div style={{ textAlign: 'left' }} className="col-sm-6">
                    <div>Oh dear! 🕵️‍♂️</div>
                    <div className="text">
                      It looks like we couldn't find any events that match the event tag you entered. <br />
                      <br />
                      We're sorry about that, but don't worry, there are still plenty of other exciting events to explore on Seaty! 🎉
                      <br />
                      <br />
                      Feel free to modify your search term, or try browsing the events on our home page. 🔍
                      <br />
                      <br />
                      We hope that you'll find the perfect event on Seaty soon! 🎫
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {useTheme ? (
            <Theme hideFooter backgroundClassName={`seaty-modal-background`} parentClassName="event-theme" className="" theme={theme}>
              <div className={`ticket-rip${isAdmin ? ' dark' : ''}`} />
              {eventPage} <div className={`ticket-rip bottom`} />
            </Theme>
          ) : (
            eventPage
          )}
          <Footer transparent={useTheme} />
        </>
      )}
    </>
  );
};

export default EventLandingPage;
