import moment from 'moment';
import { FunctionComponent } from 'react';
import CurrencyHelper from '../../../../helpers/CurrencyHelper';
import { FeedType, IFeedRow } from './IFeedRow';

export interface IProps {
  timeZoneIana: string;
  organisationId: number;
  organisationTag: string;
  currency: string;
  data: IFeedRow;
  handleRowClick: (data: any) => void;
  showEventTag: boolean;
}

const AdminFeedRow: FunctionComponent<IProps> = (props) => {
  const { data, handleRowClick, currency } = props;

  const notClickable = data.Type == FeedType.FailedOnlinePurchase || data.Type == FeedType.OnlineBalancePayment;

  let number;
  switch (data.Type) {
    case FeedType.OnlineBalancePayment:
      number = data.PurchaseId;
      break;
    case FeedType.AdminOrder:
      number = data.OrderId;
      break;
    case FeedType.RefundedPurchase: // Adding the missing FeedType
      number = data.OrderId;
      break;
    default:
      number = data.OrderId;
      break;
  }

  return (
    <>
      <tr onClick={!notClickable ? handleRowClick : null} className={`${notClickable ? '' : 'route'}`} style={{ display: 'table-row' }}>
        <td style={{ fontWeight: 500 }}>{CurrencyHelper.formatCurrency(currency, data.Amount)}</td>
        <td>
          <div
            className={`status-tag tag-${
              data.Type == FeedType.FailedOnlinePurchase ? 'bad' : data.Type == FeedType.RefundedPurchase ? ' ' : data.Type == FeedType.OnlinePurchase ? 'darkgreen' : 'good'
            }`}
          >
            {data.Type}
          </div>
        </td>
        <td>{number}</td>
        {props.showEventTag && <td>#{data.EventTag}</td>}
        <td>
          {data.AttendeeName} <span style={{ opacity: 0.7 }}>{data.Email}</span>
          {data.Type == FeedType.FailedOnlinePurchase && <div style={{ color: 'red' }}>{data.Exception}</div>}
        </td>
        <td>{props.timeZoneIana ? moment.utc(data.Date).tz(props.timeZoneIana).format(`D MMM YYYY, HH:mm`) : moment.utc(data.Date).format(`D MMM YYYY, HH:mm`)}</td>
      </tr>
    </>
  );
};

export default AdminFeedRow;
