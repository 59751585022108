import linq from 'linq';
import React, { useEffect } from 'react';
import Block from '../../../components/Block';
import BlockHeader from '../../../components/BlockHeader';
import OrderQuestions from '../../../components/OrderQuestions';
import Select from '../../../components/Select';
import CurrencyHelper from '../../../helpers/CurrencyHelper';
import NumberHelper from '../../../helpers/NumberHelper';
import SVGHelper from '../../../helpers/SVGHelper';
import { IEvent } from '../../../interfaces/IEvent';
import { IOption } from '../../../interfaces/IOption';
import { ISeatCategory } from '../../../interfaces/ISeatCategory';
import { ITicket } from '../../../interfaces/ITicket';
import { ITicketCategory } from '../../../interfaces/ITicketCategory';

export interface IProps {
  tickets: ITicket[];
  currency: string;
  seatCategories: ISeatCategory[];
  onSeatedTicketCategoryChange(seatedTickets: ITicket[]): void;
  confirmed: boolean;
  event: IEvent;
  isAdmin: boolean;
}

const OrderTicketSection: React.FC<IProps> = (props) => {
  const { tickets, currency, seatCategories, onSeatedTicketCategoryChange, confirmed, event } = props;

  useEffect(() => {
    linq
      .from(tickets)
      .where((t) => !t.GeneralAdmission)
      .toArray()
      .forEach((ticket) => {
        ticket.TicketCategories = linq
          .from(ticket.TicketCategories)
          .orderBy((tc) => tc.GroupIndex)
          .toArray();

        let tc = linq
          .from(ticket.TicketCategories)
          .orderBy((tc) => tc.GroupIndex)
          .firstOrDefault((tc) => !tc.Hide || props.isAdmin);

        if (!tc) {
          tc = linq.from(ticket.TicketCategories).orderBy((tc) => tc.GroupIndex);
        }

        ticket.TicketCategory = tc;
        ticket.TicketCategoryId = tc.Id;
      });

    onSeatedTicketCategoryChange([...tickets]);
  }, []);

  const getQuestion = (ticket: ITicket, quantity: number = 1, readOnly = false) => {
    return (
      <OrderQuestions
        event={event}
        quantity={quantity}
        ticket={ticket}
        answers={ticket.QuestionAnswers}
        onChange={(answers) => {
          ticket.QuestionAnswers = answers;
          onSeatedTicketCategoryChange([...tickets]);
        }}
        readOnly={readOnly}
      />
    );
  };

  const _onSeatedTicketCategoryChange = (seatedTicket: ITicket, option: IOption) => {
    if (seatedTicket == null || seatedTicket.TicketCategories == null) {
      return;
    }
    seatedTicket.TicketCategory = seatedTicket.TicketCategories.filter((tc) => tc.Id == option.Id)[0];
    seatedTicket.TicketCategoryId = seatedTicket.TicketCategory.Id;
    onSeatedTicketCategoryChange(tickets);
  };

  const getSeatedTicketComponents = (): JSX.Element[] => {
    let seatedTickets = linq
      .from(tickets)
      .where((t) => !t.GeneralAdmission)
      .orderBy((t) => (NumberHelper.isNumeric(t.Group) ? parseInt(t.Group) : t.Group))
      .thenBy((t) => (NumberHelper.isNumeric(t.Name) ? parseInt(t.Name) : t.Name));

    if (!event.SeatingPlans || event.SeatingPlans.length == 0) return null;

    const sections = event.SeatingPlans.map((sp) => {
      const ticketsInSeatingPlan = seatedTickets.where((t) => linq.from(sp.SeatCategories).any((sc) => sc.Id == t.SeatCategory.Id)).toArray();

      return {
        seatingPlan: sp,
        seatedTickets: ticketsInSeatingPlan,
      };
    });

    return linq
      .from(sections)
      .where((s) => s.seatedTickets.length > 0)
      .orderBy((s) => s.seatingPlan.Index)
      .toArray()
      .map((s) => {
        return (
          <React.Fragment key={s.seatingPlan.Id}>
            {sections.length > 1 && (
              <Block>
                <BlockHeader>{s.seatingPlan.Name}</BlockHeader>
              </Block>
            )}

            {s.seatedTickets.map((ticket: ITicket) => {
              let options: Array<IOption> = [];

              if (ticket.TicketCategories != null) {
                options = linq
                  .from(ticket.TicketCategories)
                  .orderBy((tc) => tc.GroupIndex)
                  .toArray()
                  .map((ticketCategory: ITicketCategory) => {
                    return !ticketCategory
                      ? {
                          Id: -1,
                          Text: 'Cannot find ticket category',
                        }
                      : {
                          Id: ticketCategory.Id,
                          Text: ticketCategory.Name,
                          TextRight: CurrencyHelper.formatCurrency(currency, ticketCategory.PriceAsInt),
                          Tags: props.isAdmin && ticketCategory.Hide ? ['Private'] : null,
                        };
                  });
              }

              const seatCategory: ISeatCategory =
                seatCategories.filter((sc: ISeatCategory) => {
                  return sc.Id === ticket.SeatCategory.Id;
                })[0] || null;

              return (
                <Block key={'seatedTicket_group_' + ticket.Group + '___seat_' + ticket.Name} className="ticket" blockRowClassName="ticket">
                  <BlockHeader>
                    <td className="ticket-font" style={{ color: seatCategory.Colour }}>
                      {ticket.Group}
                      {ticket.Name}
                    </td>
                    <td className="right" style={{ color: seatCategory.Colour }}>
                      {seatCategory.Name}
                    </td>
                  </BlockHeader>
                  <Select
                    readOnly={confirmed}
                    key={'seated_ticket_' + ticket.SeatCategory.Id + '_' + ticket.Group + '_' + ticket.Name}
                    onSelectedValueChange={(option) => _onSeatedTicketCategoryChange(ticket, option)}
                    selectedId={ticket.TicketCategory.Id}
                    options={options}
                  ></Select>
                  {seatCategory && seatCategory.Description && (
                    <div className="ticket-info">
                      <img src={SVGHelper.get('Info')} />
                      {seatCategory.Description}
                    </div>
                  )}

                  {getQuestion(ticket, 1, confirmed)}
                </Block>
              );
            })}
          </React.Fragment>
        );
      });
  };

  const getGeneralTicketComponents = (): JSX.Element[] => {
    return linq
      .from(tickets)
      .where((t) => t.GeneralAdmission && !t.Merchandise)
      .orderBy((t) => (t.TicketCategory ? t.TicketCategory.GroupIndex : 0))
      .thenBy((t) => (t.TicketCategory ? t.TicketCategory.Index : 0))
      .thenBy((t) => (NumberHelper.isNumeric(t.Group) ? parseInt(t.Group) : t.Group))
      .thenBy((t) => (NumberHelper.isNumeric(t.Name) ? parseInt(t.Name) : t.Name))
      .toArray()
      .map((ticket: ITicket) => {
        return (
          <Block key={'generalTicket_' + ticket.TicketCategory.Id} className="ticket" blockRowClassName="ticket">
            <BlockHeader>
              <td style={{ width: '28px' }}>
                <div style={{ backgroundColor: ticket.TicketCategory.Colour }} className="block-ticket-colour"></div>
              </td>
              <td>
                {ticket.Quantity} x {ticket.TicketCategory.Name}
              </td>
              <td className="right">{CurrencyHelper.formatCurrency(currency, ticket.Quantity * ticket.TicketCategory.PriceAsInt)}</td>
            </BlockHeader>
            {getQuestion(ticket, ticket.Quantity, confirmed)}
          </Block>
        );
      });
  };

  const getMerchandiseComponents = (): JSX.Element[] => {
    return linq
      .from(tickets)
      .where((t) => t.GeneralAdmission && t.Merchandise)
      .orderBy((t) => (t.TicketCategory ? t.TicketCategory.GroupIndex : 0))
      .thenBy((t) => (t.TicketCategory ? t.TicketCategory.Index : 0))
      .thenBy((t) => (NumberHelper.isNumeric(t.Group) ? parseInt(t.Group) : t.Group))
      .thenBy((t) => (NumberHelper.isNumeric(t.Name) ? parseInt(t.Name) : t.Name))
      .toArray()
      .map((ticket: ITicket) => {
        return (
          <Block key={'generalTicket_' + ticket.TicketCategory.Id} className="ticket" blockRowClassName="ticket">
            <BlockHeader>
              <td style={{ width: '28px' }}>
                <div style={{ backgroundColor: ticket.TicketCategory.Colour }} className="block-ticket-colour"></div>
              </td>
              <td>
                {ticket.Quantity} x {ticket.TicketCategory.Name}
              </td>
              <td className="right">{CurrencyHelper.formatCurrency(currency, ticket.Quantity * ticket.TicketCategory.PriceAsInt)}</td>
            </BlockHeader>
            {getQuestion(ticket, ticket.Quantity, confirmed)}
          </Block>
        );
      });
  };

  return (
    <>
      {getSeatedTicketComponents()}
      {getGeneralTicketComponents()}
      {getMerchandiseComponents()}
    </>
  );
};

export default OrderTicketSection;
