import moment from 'moment';
import 'moment-timezone';
import { FunctionComponent } from 'react';
import { IEvent } from '../../interfaces/IEvent';
import { IEventDate } from '../../interfaces/IEventDate';
import linq from 'linq';

export interface IProps {
  eventDate: IEventDate;
  isAdmin: boolean;
  event: IEvent;
  hasEnded: boolean;
  onlineFrom: moment.Moment;
  salesStarted: boolean;
}

const EventWarnings: FunctionComponent<IProps> = (props) => {
  const { event, eventDate, isAdmin: isEventAdmin, salesStarted, onlineFrom, hasEnded } = props;

  const isSuspended = eventDate && eventDate.Suspend;
  const invalidSeatingPlan = event.UseSeatingPlan && linq.from(event.SeatingPlans).any((sp) => sp.ContainsSeatsWithNoName);

  return isSuspended ? (
    <div className="info-message">Ticketing for this event date is suspended{isEventAdmin ? ' to the general public' : ''}</div>
  ) : invalidSeatingPlan ? (
    <div className="info-message">
      Warning
      <div className="text">The seating plan contains seats without a name and until this issue is corrected orders cannot be taken for this event.</div>
    </div>
  ) : hasEnded && !isEventAdmin ? (
    <div className="info-message">🎟️ Ticket sales for this event date have ended. Thank you for your interest. </div>
  ) : null;
};

export default EventWarnings;
