// import { useHistory, useLocation } from 'react-router-dom';
import linq from 'linq';
import moment from 'moment';
import 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';
import GeneralApi from '../../api/GeneralApi';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Loader from '../../components/Loader';
import LocationHelper from '../../helpers/LocationHelper';
import { IEvent } from '../../interfaces/IEvent';
import { DatesModal } from '../../modals/Dates/DatesModal';
import { LazyImage } from '../../components/LazyImage';
import DateHelper from '../../helpers/DateHelper';
import CacheHelper from '../../helpers/CacheHelper';

const SearchPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState('Loading featured events...');
  const [selectedEvent, setSelectedEvent] = useState<IEvent>(null);
  const [searchResults, setSearchResults] = useState<Array<IEvent>>([]);
  const eventUrlDetails = LocationHelper.getLocation().pathname;
  const searchTerm = eventUrlDetails.split('/')[2];
  const [loadingEventTag, setLoadingEventTag] = useState(null);

  useEffect(() => {
    if (!searchTerm || searchTerm.length === 0 || searchTerm === 'search') {
      setSearchResults(null);
      return;
    }

    setLoading('Searching for events...');

    GeneralApi.request('POST', '/api/SearchEvents', { seatingPlanOnly: false, term: searchTerm, organisationTag: '', allTime: false })
      .then((response) => {
        setLoading(null);
        setSearchResults(response);
      })
      .catch((message) => {
        alert(message);
        setLoading(null);
      });
  }, [location.search]);

  let events = linq
    .from(searchResults)
    .where((event) => {
      const now = event && moment().tz(event.TimeZoneIana);
      const onlineFrom = event && moment(event.OnlineFromDate).tz(event.TimeZoneIana);
      const salesStarted = event && onlineFrom <= now;
      return salesStarted;
    })
    .orderBy((e) => moment.utc(e.MinDate).unix())
    .groupBy((e) => e.TourId)
    .selectMany((e) => {
      const event = e.first();
      if (event.TourId) {
        return [{ ...e.first(), MinDate: event.MinDate, MaxDate: e.last().MaxDate }];
      } else {
        return e.toArray();
      }
    })
    .orderBy((e) => moment.utc(e.MinDate).unix())
    .toArray();

  return (
    <>
      <Helmet>
        <title>Seaty - A simple box office platform</title>
        <meta name="description" content={`The simple, free online box office. Sign up and let us help you sell your tickets!`} />
      </Helmet>
      <Header initialSearchTerm={searchTerm} />
      {searchResults && searchResults.length === 0 ? (
        <div className="strip-block" style={{ paddingTop: '100px', paddingBottom: '300px' }}>
          <div className="row content">
            <div style={{ textAlign: 'left' }} className="col-sm-6">
              <div>Oh dear! 🕵️‍♂️</div>
              <div className="text">
                It looks like we couldn't find any events that match your search term. <br />
                <br />
                We're sorry about that, but don't worry, there are still plenty of other exciting events to explore on Seaty! 🎉
                <br />
                <br />
                Feel free to modify your search term, or try browsing the events on our home page. 🔍
                <br />
                <br />
                We hope that you'll find the perfect event on Seaty soon! 🎫
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="events-list-strip-block-container">
          <div className="events-list-strip-block-rows">
            <div className={`strip-block events-list-strip-block`}>
              <div className="event-list">
                <div className="events-list-title">
                  <span className="highlight">{events.length}</span> Search Results
                </div>
                {events.map((eventResult) => {
                  const venueName = eventResult.Venue ? eventResult.Venue.Name : eventResult.VenueName;
                  const organisationNameMatchesVenueName = eventResult.Organisation && venueName.trim().toLowerCase() == eventResult.Organisation.Name.trim().toLowerCase();

                  return (
                    <button
                      key={`EventList_${eventResult.Id}`}
                      disabled={eventResult.EventTag == loadingEventTag}
                      className={`event-list-item`}
                      onClick={async () => {
                        if (loadingEventTag) return;
                        setLoadingEventTag(eventResult.EventTag);

                        const cachedEvent = await CacheHelper.eventByTag(eventResult.EventTag);

                        if (!eventResult.TourId && eventResult.Dates.length === 1) {
                          navigate(`/${eventResult.EventTag}/tickets/${moment(eventResult.Dates[0].DateAsString).format('DDMMMYYYY/HHmm')}`);
                        } else {
                          setSelectedEvent(cachedEvent);
                        }

                        setLoadingEventTag(null);
                      }}
                    >
                      <LazyImage src={eventResult.ImageUrl} alt={`${eventResult.Name} Event`} />
                      <div className="event-info">
                        <div className="event-name">{eventResult.Name}</div>
                        {!eventResult.TourId && !organisationNameMatchesVenueName && <div className="event-venue">{venueName}</div>}
                        <div className="event-dates">{DateHelper.fromTo(eventResult.MinDate, eventResult.MaxDate)}</div>
                        {eventResult.Organisation && <div className="event-organisation">{eventResult.Organisation.Name}</div>}
                        <div className="ticket-rip bottom" />
                      </div>
                      {eventResult.EventTag == loadingEventTag && <Loader inline />}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer />
      {selectedEvent && <DatesModal onClose={() => setSelectedEvent(null)} isAdmin={false} event={selectedEvent} />}
      {loading && <Loader>{loading}</Loader>}
    </>
  );
};

export default SearchPage;
