import React, { useRef, useState } from 'react';
import { IOption } from '../interfaces/IOption';
import BlockHeader from './BlockHeader';
import LookupOption from './LookupOption';
import BlockTextBox from './BlockTextBox';

export interface IProps {
  header: string;
  onLookupTextChange(lookupText: string): void;
  options: Array<IOption>;
  focus?: boolean;
  value: string;
  name?: string;
  type?: string;
  onLookupOptionSelected?(option: IOption): void;
  placeholder?: string;
  autoComplete?: string;
  loading?: boolean;
  rightText?: string;
}

const Lookup: React.FC<IProps> = (props) => {
  const [showOptions, setShowOptions] = useState(false);
  const ref = useRef();

  const onLookupTextChange = (value: string) => {
    props.onLookupTextChange(value);
  };

  const handleLookupOptionSelected = (option: IOption) => {
    onLookupTextChange(option.Text);

    if (props.onLookupOptionSelected) {
      props.onLookupOptionSelected(option);
    }

    setTimeout(() => {
      if (!isElementInViewport(ref.current)) {
        (ref.current as HTMLElement).scrollIntoView();
      }
    }, 200);
  };

  const propsOptions = (props.options == null ? [] : props.options).map((o, index) => (
    <LookupOption key={'Lookup_' + o.Id.toString() + '_' + index} lookupText={props.value} onClick={handleLookupOptionSelected.bind(this)} option={o} />
  ));

  const isElementInViewport = (el: Element) => {
    if (!el) return;

    var rect = el.getBoundingClientRect();

    return rect.top >= 0 && rect.left >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && rect.right <= (window.innerWidth || document.documentElement.clientWidth);
  };

  const onFocus = () => {
    setShowOptions(true);
  };

  const onLostFocus = () => {
    setTimeout(() => {
      //if (mounted) {
      setShowOptions(false);
      // }
    }, 200);
  };

  return (
    <div ref={ref} key={'lookup_' + props.header.replace(' ', '_')} className="lookup">
      <div className={'dropdown' + (propsOptions.length > 0 && showOptions ? ' has-options' : ' no-options')}>
        <BlockHeader rightText={props.rightText} key={'lookup_header_' + props.header.replace(' ', '_')}>
          {props.header}
        </BlockHeader>
        <BlockTextBox
          autoComplete={props.autoComplete}
          placeholder={props.placeholder}
          name={props.name}
          type={props.type}
          loading={props.loading}
          autoFocus={props.focus}
          key={'lookup_textbox_' + props.header.replace(' ', '_')}
          onFocus={onFocus.bind(this)}
          onLostFocus={onLostFocus.bind(this)}
          value={props.value}
          onChange={onLookupTextChange.bind(this)}
        />

        {propsOptions.length > 0 && showOptions ? <div className="options">{propsOptions}</div> : null}
      </div>
    </div>
  );
};

export default Lookup;
