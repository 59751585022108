import React, { useState, useEffect } from 'react';
import ColourHelper from '../../../helpers/ColourHelper';
import GuidHelper from '../../../helpers/GuidHelper';
import { ICategoryGroup, IEvent } from '../../../interfaces/IEvent';
import SVGTicket from '../../../svg/SVGTicket';
import Toolbar from '../toolbar/Toolbar';
import GeneralTicket from './GeneralTicket';
import linq from 'linq';
import { ITicketCategory } from '../../../interfaces/ITicketCategory';

export interface IProps {
  event: IEvent;
  onEventUpdated: (e: IEvent) => void;
  globalOptions: JSX.Element;
}

const GeneralTicketsSection: React.FC<IProps> = (props) => {
  const { event, onEventUpdated } = props;
  const [showColourPicker, setShowColourPicker] = useState(false);

  const getItems = () => {
    return linq
      .from(event.UnallocatedCategoryGroups)
      .where((e) => !e.Merchandise)
      .orderBy((s) => s.Index)
      .toArray();
  };

  const resetIndexes = () => {
    getItems().forEach((i, index) => (i.Index = index));
    props.onEventUpdated({ ...event });
  };

  useEffect(() => {
    resetIndexes();
  }, []);

  const addNew = () => {
    event.Dates.forEach((ed) => {
      if (ed) ed.ChangeMade = true;
    });

    event.UnallocatedCategoryGroups.push({
      Id: 0,
      Quantity: 1,
      SelectedEventDates: event.Dates.map((d) => d.Guid),
      HasOrders: false,
      Guid: GuidHelper.new(),
      Index: event.UnallocatedCategoryGroups.length,
      Merchandise: false,
      Categories: [
        {
          Index: 0,
          Id: 0,
          People: 1,
          Description: '',
          Name: '',
          HasOrders: false,
          PriceAsString: '',
          Colour: ColourHelper.getRandomColor(),
          Guid: GuidHelper.new(),
          GeneralAdmission: true,
          PriceAsInt: 0,
        },
      ],
    });

    resetIndexes();
  };

  return (
    <>
      <Toolbar>
        <div className="options">
          {props.globalOptions}{' '}
          <button className="option" onClick={addNew}>
            <label>Add General Ticket</label>
            <div className="icon">
              <SVGTicket />
            </div>
          </button>
        </div>
      </Toolbar>
      <div className="animated fadeIn">
        <table style={{ width: '100%' }}>
          <tbody>
            {getItems().map((group: ICategoryGroup, index) => {
              return (
                <tr key={'GeneralTicketGroup_' + group.Guid + '_' + group.Id} style={{ display: 'table-row' }}>
                  <td>
                    <GeneralTicket
                      showColourPicker={(value) => setShowColourPicker(value)}
                      handleChange={() => onEventUpdated({ ...event })}
                      onAddCategoryToGroup={() => {
                        var color = ColourHelper.getRandomColor();
                        group.Categories.push({
                          Id: 0,
                          People: 1,
                          Description: '',
                          Name: '',
                          HasOrders: false,
                          PriceAsString: '',
                          Colour: color,
                          Guid: GuidHelper.new(),
                          GeneralAdmission: true,
                          PriceAsInt: 0,
                        });

                        onEventUpdated({ ...event });
                      }}
                      onNoCategoriesRemaining={() => {
                        event.UnallocatedCategoryGroups = event.UnallocatedCategoryGroups.filter((x) => x != group);
                        onEventUpdated({ ...event });
                      }}
                      eventDates={event.Dates}
                      index={index}
                      group={group}
                      symbol={event.CurrencySymbol}
                      ticketCount={event.UnallocatedCategoryGroups ? event.UnallocatedCategoryGroups.filter((x) => !x.Merchandise).length : 0}
                      onMoveDownClicked={() => {
                        group.Index = group.Index + 1.5;

                        const newList = getItems();
                        newList.forEach((sp, index) => (sp.Index = index));
                        event.UnallocatedCategoryGroups = [...newList, ...event.UnallocatedCategoryGroups.filter((x) => x.Merchandise)];
                        props.onEventUpdated({ ...event });
                      }}
                      onMoveUpClicked={() => {
                        group.Index = group.Index - 1.5;

                        const newList = getItems();
                        newList.forEach((sp, index) => (sp.Index = index));
                        event.UnallocatedCategoryGroups = [...newList, ...event.UnallocatedCategoryGroups.filter((x) => x.Merchandise)];
                        props.onEventUpdated({ ...event });
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <button style={{ marginTop: '40px' }} className="admin-button" onClick={addNew}>
          Add a general ticket
        </button>
      </div>
    </>
  );
};

export default GeneralTicketsSection;
